@import '../../styles/mixins';

$largest-size: 1440px;
$large-size: 1280px;
$medium-size: 1024px;
$small-size: 768px;
$small-mid-size: 667px;
$smallest-size: 480px;
$ultra-smallest-size: 375px;

$transition-slow: 450ms ease;
$transition-base: 300ms ease;

$font-size-base: 16;
$font-family-base: 'Open Sans', sans-serif;
$font-family-solid: 'Montserrat', sans-serif;
$font-family-italic: 'Libre Baskerville', sans-serif;

$color-blue: #006CA9;
$color-orange: $color-blue;
$color-orange-dark: $color-blue;
$color-orange-1: #FF6B58;
//$color-orange-dark: #c94a24;

$color-laguna: #00A3AF;
$color-laguna-light: #F2FAFB;
$color-laguna-dark: #007178;
//$color-laguna-dark: #00ADB5;

$color-grey-light: #adadad;
$color-grey: #676767;

$primary-1: #ae0032;
$primary-2: #455162;
$primary-3: #1f2f46;

$neutral-1: #0d1720;
$neutral-2: #636363;
$neutral-3: #dadada;
$neutral-4: #f2f2f2;
$neutral-5: #fcfbfa;

.stats {
    &_laguna-theme {
        margin-top: -16px;
        
        background-color: $color-laguna-light;

        .stat-card {
            background-color: #ffffff;
        }

        .stats__container {
            padding-bottom: 40px;
        }
    }
}
.stats__container {
    padding-top: 0;
    padding-bottom: 0;
}
.stats__list {
    display: grid;
    justify-content: stretch;
    grid-row-gap: 15px;

    @media (min-width: $ultra-smallest-size) {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 15px;
    }
    @media (min-width: $small-size) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: $large-size) {
        grid-template-columns: repeat(6, 1fr);
    }
    @media (min-width: $largest-size) {
        grid-column-gap: 32px;
    }
}
.stats__item {
    height: var(--card-height);
}

.stat-card {
    height: 100%;
    padding: 15px;

    background-color: $color-laguna-light;
    border-radius: 10px;

    @media (min-width: $small-size) {
        padding: 20px 24px 20px 32px;
    }
}
.stat-card__rating {
    display: flex;
    align-items: center;

    height: 100%;
}
.stat-card__value {
    color: $color-orange;
}
.stat-card__text {
    font-size: 14px;

    @media (min-width: $large-size) {
        max-width: 135px;
    }
}
.stat-rating {
    text-decoration: none;
}
.stat-rating__wr-logo {
    width: 100px;
}
.stat-rating__logo {
    object-fit: contain;
}

.stat-rating__wr-rate {
    display: flex;
    align-items: flex-end;
}
.stat-rating__rate {
    margin-right: 6px;

    font-size: 18px;
    color: $color-orange;
}
.stat-rating__rate-count  {
    margin-left: 8px;
    margin-bottom: 1px;

    font-size: 14px;
    color: #676767;
}

.stat-rating__stars .holy-stars__entity:not(:last-child) {
    margin-right: 2px;
}